body {
  margin: 0;
}

#root {
  font-family: Lato;
  height: 100%;
  min-height: 100vh;
  font-size: 18px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("/src/assets/bg.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (min-width: 640px) {
  .App {
    justify-content: center;
  }
}

/* library specific styles start */
.swal2-popup {
  font-size: 0.6rem;
  font-family: Lato;
}

.slick-dots {
  bottom: -15px;
}

.slick-dots li button:before {
  font-size: 10px;
}

.react-tel-input .flag-dropdown {
  top: 17px !important;
  background: #f3f2fe !important;
  width: 65px !important;
  height: 30px !important;
  border-radius: 8px !important;
  margin-right: 20px !important;
}

.react-tel-input .form-control {
  padding: 19px 14px 4px 0 !important;
  margin: 3px 0 0 75px !important;
  width: 75% !important;
  font-family: Lato !important;
}
/* library specific styles end */
